import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import "../../css/custom-css/custom-products-services.css"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/products/banner-produk.png"
import Seo from "../../components/seo"

const SarsCovSalivaNucleic = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit" />

      <Header productsPage />

      <BannerHeader
        background={bg}
        title="Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="">
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/indigen-real-time">
                        INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/diago-t790m">
                        DIAGO T790M Mutation Detection Kit
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/products/elvasalivaNew.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div class="row m-b30">
                      <div class="col-lg-12">
                        <div class="dlab-tabs  product-description tabs-site-button">
                          <h4 className="dlab-title mt-2">
                            Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test
                            Kit
                          </h4>
                          <Tabs>
                            <TabList className="nav nav-tabs ">
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Description</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Test Principle</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Intended Use</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Additional Information</Link>
                              </Tab>
                            </TabList>

                            <TabPanel class="tab-pane">
                              <p className="text-justify">
                                Coronaviruses are a type of virus. There are
                                many different kinds, and some cause disease. A
                                newly identified coronavirus, SARS-CoV-2, has
                                caused a worldwide pandemic of respiratory
                                illness, called COVID-19, that was first
                                identified in December 2019. The new coronavirus
                                can be spread from person to person. Diagnosis
                                may be difficult with only a physical exam
                                because mild cases of COVID-19 may appear
                                similar to the flu or a bad cold. A laboratory
                                test can confirm the diagnosis. There are
                                several types of tests measuring different
                                things, and new innovations and strategies are
                                emerging all the time. Nasal and throat swabs
                                are uncomfortable—and that’s particularly
                                problematic for those who have to get tested
                                frequently—so our research center in Jakarta,
                                named Stem Cell and Cancer Institute (SCI) of
                                Kalbe, are studying and produce innovation
                                Covid-19 test that less invasive methods with
                                saliva tests, it is a promising alternative that
                                could simplify and accelerate COVID-19
                                diagnosis. The results of this research were
                                produced by PT KalGen DNA and marketed by PT
                                Enseval Medika Prima (EMP), under the name ELVA
                                Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test
                                Kit. ELVA Diagnostic SARS-CoV-2 Saliva Nucleic
                                Acid Test Kit has received distribution
                                authorization from Kementerian Kesehatan
                                Republic of Indonesia with number KEMKES RI AKD
                                20303120508.
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                The Elva Diagnostic SARS-CoV-2 Saliva Nucleic
                                Acid Test Kit uses reverse-transcription
                                loopmediated isothermal amplification (RT-LAMP)
                                method to perform in vitro amplification of
                                SARS-CoV2 nucleic acid and enables colorimetric
                                qualitative detection. The assay utilizes a
                                unique primer set to detect the nucleocapsid (N)
                                gene region of SARS-CoV-2 RNA. Internal control
                                (IC) primer set is also included that targets
                                the region of human RNase P gene. Each primer
                                set consists of 6 primer strands that target the
                                specific regions of viral RNA or human DNA.
                              </p>
                              <StaticImage src="../../images/products/infosaliva2.png" />
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                The Elva Diagnostic SARS-CoV-2 Saliva Nucleic
                                Acid Test Kit is a reverse-transcription
                                loop-mediated isothermal amplification
                                (RT-LAMP)-based in vitro diagnostic assay
                                intended for the qualitative colorimetric
                                detection of SARS-CoV-2 virus nucleic acid in
                                the saliva specimen from suspected individuals
                                with COVID-19 by the healthcare provider.
                                Positive result indicates the presence of
                                SARS-CoV-2. Negative result does not always mean
                                that the patient has not been infected by the
                                pathogens, it only shows that the genetic
                                material of SARS-CoV2 was not found in the
                                sample at the time of specimen collection.
                                Results should not be determined as the sole
                                basis for decision in health management of
                                patients. If the observed clinical symptoms and
                                epidemiological information of the patient are
                                not consistent with the results, further
                                healthcare assessment for the patient is
                                strongly recommended.   The Elva Diagnostic
                                SARS-CoV-2 Saliva Nucleic Acid Test Kit is
                                intended for use by the qualified clinical
                                laboratory personnel, specifically instructed
                                and trained in the techniques of RT-LAMP and in
                                vitro diagnostic procedures.
                              </p>
                              <StaticImage
                                src="../../images/products/infosaliva3.png"
                                className="mt-3"
                              />
                            </TabPanel>

                            <TabPanel class="tab-pane">
                              <StaticImage src="../../images/products/infosaliva1.png" />
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default SarsCovSalivaNucleic
